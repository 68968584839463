

.pnfContainer{
  text-align: center;
  color: var(--black);
  padding-top: 10px;
}


.pnfLink{
  text-decoration: none;
  color: var(--black);
  padding: 0 20px;
  transition: var(--transition);
}

.pnfLinkDiv{
  padding: 0 0 30px 0;
}
.pnfLink:hover{
  color: var(--purple);
}

.pnfImageClass{
width: 90vw;
padding-bottom: 20vh;
}


@media all and (min-width: 900px){
  
  .pnfImageClass{
    width: 700px;
    }
}