/* import fonts */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

/* Root style guide for color palette and transitions */

:root
{
  --black: #4d4d4d;
  --white: #e7e7e7;
  --dark-white: #dddcdc;
  --bright-white: #f2f0f0;
  --gray: #afaca8;
  --gray-highlight: #7d8e76;
  --blue: rgb(31, 105, 150);
  --blue-highlight: rgb(35, 118, 160);
  --green: rgb(120, 182, 120);
  --yellow: rgba(253, 249, 228, 0.895);
  --purple: rgb(55, 45, 81);
  --lightpurple: rgb(93, 64, 113);
  --brown: rgb(191, 200, 174);
  --brown-highlight: rgb(191, 200, 174); ;
  --lightgreen: rgb(165, 197, 153);
  --lightgreen-highlight: rgb(206, 228, 144); 
  --dark-brown: rgb(19, 18, 20);
  --transition: all 0.25s ease-in-out;
  --transition-slider: all 1s;
}

.appContainer {
  padding-top: 100px;
}

body{
  background-image: linear-gradient(140deg, var(--white), var(--white));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
}
