/* CSS set up for mobile first and Queries the larger screens */
/* Gallery Images defined here in CSS */

.before-container{
  background-image: url('../images/13-142-209-walls.jpeg');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.475);
  width: 99.6%;
  height: 17.6vh;
  /* border: solid green; */
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border: 1px solid var(--gray);
  transition: var(--transition);
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.before-container:hover{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  cursor: pointer;
}

.stone-container{
  background-image: url('../images/IMG_1187-600x450-walls.jpeg');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.475);
  width: 99.6%;
  height: 17.6vh;
  /* border: solid green; */
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border: 1px solid var(--gray);
  transition: var(--transition);
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.stone-container:hover{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  cursor: pointer;
}

.walk-container{
  background-image: url('../images/IMG_0355_600x450-walk.jpeg');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.475);
  width: 99.6%;
  height: 17.6vh;
  /* border: solid green; */
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border: 1px solid var(--gray);
  transition: var(--transition);
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}


.walk-container:hover{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  cursor: pointer;
}

.pool-container{
  background-image: url('../images/13-142-151-pool.jpeg');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.475);
  width: 99.6%;
  height: 17.6vh;
  /* border: solid green; */
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border: 1px solid var(--gray);
  transition: var(--transition);
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.pool-container:hover{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  cursor: pointer;
}

.backyard-container{
  background-image: url('../images/13-142-363-backyard.jpeg');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.475);
  width: 99.6%;
  height: 17.6vh;
  /* border: solid green; */
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border: 1px solid var(--gray);
  transition: var(--transition);
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.backyard-container:hover{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  cursor: pointer;
}


@media all and (min-width: 930px) {

  .gallery-container{
    display: flex;
    transform: translateY(-5px); 
  }

  .before-container{
    height: 88.5vh;
    font-size: 20px;
  }

  .stone-container{
    height: 88.5vh;
    font-size: 20px;
  }

  .walk-container{
    height: 88.5vh;
    font-size: 20px;
  }

  .pool-container{
    height: 88.5vh;
    font-size: 20px;
  }

  .backyard-container{
    height: 88.5vh;
    font-size: 20px;
  }
}