/* CSS set up for mobile first and Queries the larger screens */

.footerContainer{
  background-color: var(--black);
  height: 100%;
}

.footerContainer-flex1{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContainer-flex2{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content-h3{
 color: var(--white);
 margin: 0;
 padding: 20px 10px 5px 10px;
 font-size: 18px;
}

.footer-content-phone{
  color: var(--white);
  margin: 0;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 18px;
  transition: var(--transition);
  cursor: pointer;
 }

 .footer-content-phone:hover{
  color: white;
 }

 .footer-content-plant{
  color: var(--white);
  margin: 0;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 18px;
  transition: var(--transition);
  cursor: pointer;
 }

 .footer-content-plant:hover{
  color: white;
 }

 .footer-content-careers{
  color: var(--white);
  margin: 0;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 18px;
  transition: var(--transition);
  cursor: pointer;
 }

 .footer-content-careers:hover{
  color: white;
 }

 .footer-content-facebook-img{
  margin: 0;
  padding: 10px 0 20px 0;
  width: 25px;
 }

 .footer-content-facebook-a{
  text-decoration: none;
  cursor: pointer;
 }

 .footer-portfolio{
  font-size: 9px;
  color:var(--gray-highlight);
 }

 .footer-portfolio-link{
  text-decoration: none;
  color:var(--gray-highlight);
  cursor: pointer;
 }


@media all and (min-width: 1000px){

  
.footer-portfolio{
  margin-left: auto;
  margin-right: 20px;
}

.footerContainer{
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
}

.footerContainer-flex1{
  flex-direction: row;
  justify-content: center;
}

.footerContainer-flex2{
  flex-direction: row;
  justify-content: center;
}

.footer-content-h3{
  padding:5px 10px 5px 0px;
 }

 .footer-content-facebook-img{
  padding: 18px 10px 10px 16px;
 }

}