/* CSS set up for mobile first and Queries the larger screens */

.beforeAfter-container{
   background-image: linear-gradient( var(--white),  var(--dark-white) ); 
  padding-bottom: 40px;
}


.beforeAfter-container-flex{
  display: flex;
  flex-direction: column;
} 

.BeforeAfter-h3{
  font-size: 20px;
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.return-before{
  display: flex;
  text-decoration: none;
  color: var(--black);
  padding-top: 10px;
  padding-left: 10px;
  transition: var(--transition);
}

.return-before:hover{
  color: var(--purple);
}


@media all and (min-width: 600px) and (max-width: 929px) {


  .BeforeAfter-h3{
    font-size: 30px;
    padding: 20px 0 10px 0;
  }

  .return-before{
    padding-top: 10px;
    padding-left: 20px;
  }
  

}

@media all and (min-width: 930px) and (max-width: 999px) {

  .beforeAfter-container{
    transform: translateY(-8px); 

  }

  .BeforeAfter-h3{
    font-size: 30px;
    padding: 20px 0 20px 0;
  }

  .return-before{
    padding-top: 30px;
    padding-left: 40px;
  }

}

@media all and (min-width: 1000px) and (max-width: 1299px) {


  .beforeAfter-container{
    transform: translateY(-5px); 
  }

  .beforeAfter-container-flex{
    flex-direction: row; 
    margin: 10px auto 60px auto;
    width: 950px;
  }


.BeforeAfter-h3{
  font-size: 30px;
  padding: 20px 0 20px 0;
}

.return-before{
  padding-top: 30px;
  padding-left: 40px;
}
}

@media all and (min-width: 1300px) {


  .beforeAfter-container{
    transform: translateY(-5px); 
  }

  .beforeAfter-container-flex{
    flex-direction: row; 
    margin: 10px auto 60px auto;
    width: 1050px;
  }


.BeforeAfter-h3{
  font-size: 30px;
  padding: 20px 0 20px 0;
}

.return-before{
  padding-top: 30px;
  padding-left: 40px;
}

}