
/* CSS set up for mobile first and Queries the larger screens */

.services-container{
background-color: var(--white);
padding-bottom: 20px;
display: flex;
flex-direction: column;
align-items: center;
} 

.services-container-h3{
 color: var(--black);
 text-align: center;
 margin: 0 0 40px 0;
 padding-top: 40px;
 font-size: 26px;
 font-family: 'Lato', sans-serif;
 border-bottom: 1px solid rgba(116, 76, 23, 0.9);
 width: 110px;
 }

.services-content h3{
 margin:  10px 0 0 0;
 color: var(--black);
 padding-bottom: 10px;
}

.services-content p{
  margin: 0;
 }

.services-content{
  text-align: center;
  margin: 0 auto;
  padding: 20px 20px;
  width: 280px;
  
}

.services-a-header{
  color: var(--black);
  margin: 30px 0 0 0;
  text-align: center;
  padding: 0 10px;
  font-size: 24px;
}

.services-a{
  color: var(--white);
  font-size: 18px;
  background-image: linear-gradient(90deg,var(--blue), var(--blue-highlight));
  background-size: 300% auto;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px var(--black);
  transition: var(--transition);
  text-decoration: none;
  margin: 50px 40px 50px 40px;
} 


.services-a:hover{
  background-position: right center;
  box-shadow: 1px 1px 6px var(--black);
  color: white;
  cursor: pointer;
  font-size: 18.5px;
}




@media all and (min-width: 640px) and (max-width: 799px){

  .services-container-h3{
    margin: 0 0 30px 0;
    font-size: 30px;
    width: 120px;
  }

  .services-content-flex{
    display: grid;
    grid-template-columns: 1fr 1fr ;
  }

}

@media all and (min-width: 800px) and (max-width: 899px){

  .services-container-h3{
    margin: 0 0 30px 0;
    font-size: 30px;
    width: 120px;
  }

  .services-content-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .services-a{
    font-size: 20px;
  } 
  
  .services-a:hover{
    font-size: 20.5px;
  }

}

@media all and (min-width: 900px) and (max-width: 999px){

  .services-container-h3{
    margin: 0 0 30px 0;
    font-size: 36px;
    width: 140px;
  }

  .services-content-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .services-a{
    font-size: 20px;
  } 
  
  .services-a:hover{
    font-size: 20.5px;
  }

}



@media all and (min-width: 1000px){

  .services-container-h3{
    margin: 0 0 30px 0;
    font-size: 36px;
    width: 140px;
  }

  .services-content-flex{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .services-a{
    font-size: 20px;
  } 
  
  .services-a:hover{
    font-size: 20.5px;
  }

}


