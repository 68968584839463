/* CSS set up for mobile first and Queries the larger screens */

.contact-container{
margin: 0 0 50px 0;
}

.map{
  border:0;
  width:300px;
  height:350px;
  margin: 20px 0 40px 0;
}

.map-container{
  /* border: solid green;  */
  display: flex;
  flex-direction: column;
}


.map-container-title{
  color: var(--black);
  margin: 0;
  font-size: 20px;
  text-align: center;
 }

.map-container-address{
 color: var(--black);
 margin: 0;
 text-align: center;
 
}


.map-container-phone{
  text-decoration: none;
  color: var(--black);
  font-size: 22px;
  transition: var(--transition);
  margin: 10px 0;
  text-align: center;

 }
 
 .map-container-email{
   text-decoration: none;
   color: var(--black);
   font-size: 22px;
   transition: var(--transition);
   margin: 0 0 10px 0;
   text-align: center;
   
  }
 

.map-container-phone:hover{
 color: var(--blue-highlight);
 cursor: pointer;
}

.map-container-email:hover{
  color: var(--blue);
  cursor: pointer;
 }


.contact-flex{
  /* border: solid blue;  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.contact-section{
  /* border: solid red; */
  width: 300px;
  margin: 0 auto;
}

.contact-section h3{
  color: var(--black);
  text-align: center;
  font-size: 24px; 
  font-family: 'Lato', sans-serif;
}

.contact-h3{
  color: var(--black);
  font-size: 24px; 
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin: 30px 0;
}

.contact-message{
  resize: none;
  padding: 12px 20px;
  font-size: 18px;
  margin: 10px auto;
}

.contact-section input{
  width: 85%;
  padding: 18px 20px;
  font-size: 18px;
  margin: 10px auto;
}


.contact-section-btn{
  margin: 10px auto;
  color: var(--white);
  font-size: 18px;
  background-image: linear-gradient(90deg,var(--blue), var(--blue-highlight));
  background-size: 300% auto;
  padding: 10px 20px;
  box-shadow: 1px 1px 0px var(--black); 
  transition: var(--transition);
  text-decoration: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
} 


.contact-section-btn:hover{
  background-position: right center;
  box-shadow: 1px 1px 6px var(--black);
  cursor: pointer;
  font-size: 18.5px;
  color: white;
}


@media all and (min-width: 800px) and (max-width: 929px) {


  .contact-h3{
    font-size: 30px;
  }

  .contact-section h3{
    font-size: 30px;
  }

}

@media all and (min-width: 930px) {

    
  .contact-h3{
    font-size: 36px;
  }

  .contact-section h3{
    font-size: 36px;
  }

  .contact-flex-mid{
   display: flex ;
  }

  .map{
    border:0;
    width:400px;
    height:575px;
    margin: 48px 0px 80px 60px;
  }

}
