
/* CSS set up for mobile first and Queries the larger screens */

/* --------------------- HEADER CSS --------------------- */

.header-shadow{
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 4;
}

.header {
  height: 100px;
  width: 100%;
  background-image: linear-gradient( 90deg, var(--blue), var(--blue-highlight)); 
  vertical-align: middle;
  display: flex;
  /* clip-path: polygon(0 0, 100% 0, 100% 82%, 68% 82%, 58% 100%, 0 100%); */
  box-shadow: 1px 1px 4px var(--black);
}

.header-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 50px 5px 30px;
  width: 100%;
 
}

.header img {
  object-fit: contain;
  height: 90px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.header .menu {
  position: absolute;
  top: 100px;
  height: 0px;
  overflow-y: hidden;
  background-color: rgba(44, 33, 67, 0.6);
  width: 100%;
  transition-property: height;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  display: flex;
  flex-direction: column;
}

.header .menu.active {
  height: 100vh;
}


.nav-item {
  padding: 10px 5px;
  background-color: var(--white);
  border: 1px solid var(--gray);
  opacity: 1;
  font-size: 18px;
  text-align: center;
}

.nav-item-a{
  text-decoration: none;
  color: var(--black);
  transition: var(--transition);
}

.nav-item-a:hover{
  color: var(--gray-highlight);
}

/* --------------------- HAMBURGER CSS --------------------- */

.hamburger{
  cursor: pointer;
}

.hamburger span{
  display: block;
  width: 50px;
  height: 3px;
  background-color: var(--white);
  margin: 8px 0px;
  transition: var(--transition);
}

.hamburger.active span:nth-of-type(1) {
  transform: rotate(45deg) translateX(13px) translateY(-3px);
  margin:-9px;
}

.hamburger.active span:nth-of-type(2) {
  opacity: 0;
}

.hamburger.active span:nth-of-type(3) {
  transform: rotate(-45deg) translateX(6px) translateY(10px);
  margin:-9px;
}

/* --------------------- FULL SCREEN CSS --------------------- */

@media all and (min-width: 930px) {

 
  .header-shadow{
    filter: drop-shadow(1px 3px 3px rgba(41, 44, 41, 0.501))
  }
  
  .header{
    clip-path: polygon(0 0, 100% 0, 100% 88%, 30% 88%, 27% 100%, 0 100%);
  }


  .header .menu {
    position: static;
    height: auto;
    overflow-y: visible;
    background-color: transparent;
    flex-direction: row;
    justify-content: space-around;
    width: 75vw;
    padding: 5px 20px;
  }

  .header-content{
    width: 25vw;
    padding: 5px 30px 5px 30px;
    justify-content: center;
   
  }

  .hamburger {
    display: none;
  }
 
  .nav-item {
    border: none;
    background-color: transparent;
    padding: 30px 10px 10px 10px;
    
  }

  .nav-item-a {
    color: var(--white);
  }

  .nav-item-a:hover{
    color: white;
  }
  
}

