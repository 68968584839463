/* CSS set up for mobile first and Queries the larger screens */


.about-hero-container{
  background-image: url('../images/Team.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 30vh;
  margin: 0 auto;
}

.about-section-container-shadow{
  filter: drop-shadow(1px 1px 7px rgba(116, 76, 23, 0.25));
}

.about-section-container{
  background-color: var(--white);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  color: var(--black);

}

.about-section-h3{
  border-bottom: 1px solid rgba(116, 76, 23, 0.9);
  width: 110px;
  margin: 50px auto 30px auto;
  font-size: 24px; 
  font-family: 'Lato', sans-serif;
}

.about-section-p{
  font-size: 18px; 
  margin: 10px 10px;
}

.about-section-container a{
  color: var(--white);
  font-size: 18px;
  width: 210px;
  margin: 30px auto 10px auto;
  background-image: linear-gradient(90deg,var(--blue), var(--blue-highlight));
  background-size: 300% auto;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px var(--black);
  transition: var(--transition);
  text-decoration: none;

}

.about-section-container a:hover{
  background-position: right center;
  box-shadow: 1px 1px 6px var(--black);
  color: white;
  cursor: pointer;
  font-size: 18.5px;
  padding: 10px 20px;
  width: 220px;
  
}


/* ------------------- TEAM CSS ---------------- */



.about-team-container{
  padding: 40px 20px 20px 20px;
  background-color: var(--brown);
  text-align: center;
}

.about-team-container-style{
  /* box-shadow: 2px 4px 8px var(--dark-brown); */
  padding-top: 20px;
  /* background-color: var(--black); */
  margin: 0 auto 20px auto;
  max-width: 400px;
}

.about-team-h3{
  font-size: 24px; 
  color: var(--black);
  text-align: center;
  margin: 30px;
  font-family: 'Lato', sans-serif;
}

.about-team-image{
  box-shadow: 2px 4px 8px var(--dark-brown);
  border-radius: 2px;
}

.about-team-name{
 color: var(--black);
 margin: 10px 0 0 0;
}

.about-team-p{
  color: var(--black);
  padding: 10px;
  margin: 0;
}


/* ------------------- TESTIMONIALS CSS ---------------- */


.about-testimonials-container{
  /* border: solid yellow; */
  background-image: linear-gradient( var(--brown), var(--brown)); 
  padding-bottom: 20px;
}

.about-testimonials-h3{
 text-align: center;
 color: var(--purple);
 margin: 0;
 padding: 10px 0 40px 0;
 font-size: 24px;
 font-family: 'Lato', sans-serif;
}

.about-testimonials-content-container{
  margin: 0 20px;
  text-align: center;
}

.about-testimonials-content-p-containers{
 background-color: var(--white);
 margin: 0 0 20px 0;
 border-radius: 2px;
 box-shadow: 1px 1px 4px var(--purple);
}


.about-testimonials-content-p-containers img{
  width: 24px;
  margin: 30px 0 0 0;
  color: var(--black);
}

.about-testimonials-content-p-containers p{
  color: var(--black);
  margin: 10px 26px;
  padding: 0 0 20px 0;
  text-align: center;
}

.about-testimonials-content-p-containers span{
  font-weight: bold;
}



@media all and (min-width: 800px) and (max-width: 929px) {


.about-section-h3{
  width: 140px;
  font-size: 30px;
}

.about-section-p{
  margin: 10px 40px;
}

.about-team-h3{
  font-size: 30px; 
}

.about-testimonials-h3{
  font-size: 30px;
 }


.about-team-container{
  display: flex;
}

.about-team-container-style{
  width: 350px;
}

.about-testimonials-content-container{
  display: flex;
  justify-content: center;
  margin: 6px 20px 60px 20px;
}

.about-testimonials-content-p-containers{
  max-width: 220px;
  height: 510px;
  overflow-y: scroll ;
  margin: 0px 14px;
}

.about-testimonials-content-p-containers p{
  text-align: left;
}

}


@media all and (min-width: 930px) and (max-width: 1049px) {


  .about-hero-container{
    transform: translateY(-12px);
  }
  
  .about-section-container{
    transform: translateY(-12px);
  }

  .about-team-container{
    display: flex;
  }

    .about-section-h3{
      width: 160px;
      font-size: 36px;
    }
  
  .about-section-container a{
    font-size: 20px;
    width: 230px;
  }
  
  .about-section-container a:hover{
    font-size: 20.5px;
    width: 240px;
  }

  .about-section-p{
    font-size: 20px; 
    margin: 10px 60px;
  }
  
  .about-team-h3{
    font-size: 36px; 
  }
  
  .about-testimonials-h3{
    font-size: 36px;
   }
  
  .about-team-container-style{
    width: 350px;
  }
  

  .about-testimonials-content-container{
    display: flex;
    justify-content: center;
    margin: 6px 20px 60px 20px;
  }
  
  .about-testimonials-content-p-containers{
    max-width: 240px;
    height: 480px;
    overflow-y: scroll ;
    margin: 0 20px;
  }

  .about-testimonials-content-p-containers p{
    text-align: left;
  }
  
}

@media all and (min-width: 1050px) and (max-width:1299px) {


  .about-hero-container{
    transform: translateY(-12px);
  }
  
  .about-section-container{
    transform: translateY(-12px);
  }

  .about-section-h3{
    width: 160px;
    font-size: 36px;
  }

  .about-section-container a{
    font-size: 20px;
    width: 230px;
  }
  
  .about-section-container a:hover{
    font-size: 20.5px;
    width: 240px;
  }


  .about-section-p{
    font-size: 20px; 
    margin: 10px 100px;
  }
  
  
  .about-team-h3{
    font-size: 36px; 
  }
  
  .about-testimonials-h3{
    font-size: 36px;
   }

  .about-team-container{
    display: flex;
    padding: 40px 150px 20px 150px;
  }
  
  .about-team-container-style{
    width: 350px;
  
  }  
  
  .about-testimonials-content-container{
    display: flex;
    justify-content: center;
    margin: 6px 20px 60px 20px;
  }
  
  .about-testimonials-content-p-containers{
    max-width: 280px;
    height: 480px;
    overflow-y: scroll ;
    margin: 0 20px;
  }

  .about-testimonials-content-p-containers p{
    text-align: left;
  }
  
  }


  @media all and (min-width: 1300px) {


    .about-hero-container{
      transform: translateY(-12px);
    }
    
    .about-section-container{
      transform: translateY(-12px);
    }
  
    .about-section-h3{
      width: 160px;
      font-size: 36px;
    }
  
    .about-section-container a{
      font-size: 20px;
      width: 230px;
    }
    
    .about-section-container a:hover{
      font-size: 20.5px;
      width: 240px;
    }
  
  
    .about-section-p{
      font-size: 20px; 
      margin: 10px 300px;
    }
    
    
    .about-team-h3{
      font-size: 36px; 
    }
    
    .about-testimonials-h3{
      font-size: 36px;
     }
  
    .about-team-container{
      display: flex;
      justify-content: center;
      padding: 40px 250px 20px 250px;
    }
    
    .about-team-container-style{
      width: 350px;
      margin: 0 80px 20px 80px;
    
    }  
    
    .about-testimonials-content-container{
      display: flex;
      justify-content: center;
      margin: 6px 20px 60px 20px;
    }
    
    .about-testimonials-content-p-containers{
      max-width: 310px;
      height: 480px;
      overflow-y: scroll ;
      margin: 0 30px;
    }
  
    .about-testimonials-content-p-containers p{
      text-align: left;
    }
    
    }