/* CSS set up for mobile first and Queries the larger screens */

.slider-container{
  /* border: solid red;  */
  width: 280px;
  margin: 0 auto;
  padding: 10px 10px 0 10px;
  background-color: var(--white);
  
}

#slider{
  appearance:none;
  background-color: rgba(255,255,255,0);
  width: 99%; 
  transform: translateY(-80%);
  position: relative;
  z-index: 3;
  cursor: pointer;
  padding: 40px 0 0 0;
}


.sliderImage{
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
  z-index: 2;
  border-right: 1px solid var(--gray);
}

.sliderImageAfter{
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%; 
  height: 200px;
  position: relative;
  z-index: 1;
  border: 1px solid var(--gray);
  
}


/* slider thumb */
#slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
   appearance: none;
   margin-top: -12px; /* Centers thumb on the track */

   /*custom styles*/
   background-image: linear-gradient(var(--bright-white), var(--lightpurple));
   border-radius: 50px;
   height: 30px;
   width: 30px;
   box-shadow: 1px 1px 4px var(--black);
}


.slider-p{
  text-align: center;
  color: var(--purple);
}



@media all and (min-width: 400px) and (max-width: 599px) {

  .slider-container{
    width: 360px;
  }

  .sliderImage{
    height:280px;
  }

  .sliderImageAfter{
    height:280px;
  }


}


@media all and (min-width: 600px) and (max-width: 799px) {

  .slider-container{
    width: 400px;
  }

  .sliderImage{
    height:300px;
  }

  .sliderImageAfter{
    height:300px;
  }


}

@media all and (min-width: 800px) {

  .slider-container{
    width: 400px;
  }

  .sliderImage{
    height:300px;
  }

  .sliderImageAfter{
    height:300px;
  }


}