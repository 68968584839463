
/* CSS set up for mobile first and Queries the larger screens */

/* ------------------------- HERO CSS -------------------------- */


.home-container{
  transform: translateY(-62px);  
}


.hero-container{
  background-image: url('../images/IMG_4174.jpeg') ;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 82vh;
  padding: 70px 0;
  text-align: center;
  
}

.hero-container h1{
  margin: 40px 0 0 10px;
  font-size: 8vw; 
  color: var(--white);
  font-family: 'Lato', sans-serif;
}

.hero-container h1 span{
  font-size: 6vw; 
  display: block;
}

.hero-container h2{
  margin: 30px 10px 60px 10px;
  font-size: 16px; 
  color: var(--white);
}


.hero-container a{
  color: var(--white);
  font-size: 18px;
  background-image: linear-gradient(90deg,var(--blue), var(--blue-highlight));
  background-size: 300% auto;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px var(--black);
  transition: var(--transition);
  text-decoration: none;
  position: relative;
  z-index: 1;

}

.hero-container a:hover{
  background-position: right center;
  box-shadow: 1px 1px 6px var(--black);
  cursor: pointer;
  font-size: 18.5px;
  padding: 10px 20px;
  color: white;
}


.pool-highlight{
  background-image: url('../images/PoolHighlights.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 82vh;
  padding: 70px 0;
  position: absolute;
  top: 0px;
  left: -3px;
  animation: animate 1s linear infinite;
}

@keyframes animate{
  0%{
    
    transform: translateX(-.2%);
  }
  50%{
    transform: translateX(.1%);
  }
  100%{
    transform: translateX(-.2%);
    
  }
}

/* ------------------------- WELCOME CSS -------------------------- */

.home-welcome-statement-container a{
  color: var(--white);
  font-size: 18px;
  background-image: linear-gradient(90deg,var(--blue), var(--blue-highlight));
  background-size: 300% auto;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px var(--black);
  transition: var(--transition);
  text-decoration: none;
  position: relative;
  z-index: 1;
  margin-top: 40px;

}

.home-welcome-statement-container a:hover{
  background-position: right center;
  box-shadow: 1px 1px 6px var(--black);
  cursor: pointer;
  font-size: 18.5px;
  padding: 10px 20px;
  color: white;
}

.home-welcome-details-container{
  /* border: solid green;  */
  place-items: center;
  transform: translateY(62px);  
}

.home-welcome-statement-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 130px;
  background-color: var(--white);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}

.home-welcome-statement-container-shadow{
  filter: drop-shadow(1px 1px 7px rgba(116, 76, 23, 0.25));
}

.home-bio{
  margin: 10px;
  text-align: center;
  max-width: 800px;
  font-size: 18px;
  color: var(--black);
}

.home-welcome-details-container-bg{
  /* border: solid slateblue; */
  margin: 10px 0 0 0;
  background-image: linear-gradient( var(--brown-highlight),  var(--brown) );
  padding: 40px 0 60px 0;
  
}

.home-welcome-statement-container h3{
  margin: 80px 10px 30px 10px;
  text-align: center;
  font-size: 24px;
  border-bottom: 1px solid rgba(116, 76, 23, 0.9);
  padding: 0 10px 6px 10px;
  color: var(--black);
  font-family: 'Lato', sans-serif;

}

.home-welcome-details-intro{
  text-align: center;
  font-size: 24px;
  margin: 0 auto 10px auto;
  color: var(--black);
  font-family: 'Lato', sans-serif;
}


.home-welcome-details{
  text-align: center;
  max-width: 950px;
  margin: 0 10px;
}
.home-welcome-details-alt{
  text-align: center;
  max-width: 950px;
  margin: 0 10px;
}


.home-welcome-details-img-expertise{
 background-image: linear-gradient(rgba(23, 23, 23, 0.6), rgba(23, 23, 23, 0.6)), url('../images/13-142-209-walls.jpeg');
 height: 80vw;
 width: 80vw;
 background-size: cover;
 background-position: center;
 margin: 0 auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 box-shadow: 0px 8px 8px var(--dark-brown);
}
.home-welcome-details-img-flexibility{
  background-image: linear-gradient(rgba(23, 23, 23, 0.6), rgba(23, 23, 23, 0.6)), url('../images/13-142-296-walls.jpeg');
  height: 80vw;
  width: 80vw;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 8px var(--dark-brown);
  
 }
 .home-welcome-details-img-detail{
  background-image: linear-gradient(rgba(23, 23, 23, 0.6), rgba(23, 23, 23, 0.6)), url('../images/IMG_1185-600x450-walls.jpeg');
  height: 80vw;
  width: 80vw;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 8px var(--dark-brown);
 }


 .home-welcome-details-div-p-expertise{
  background-color: var(--white);
  height: 80vw;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 8px var(--dark-brown);
 }
 .home-welcome-details-div-p-flexibility{
  background-color: var(--black);
  height: 80vw;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 8px var(--dark-brown);
 }
 .home-welcome-details-div-p-detail{
  background-color: var(--blue);
  height: 80vw;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 8px var(--dark-brown);
 }


 .home-welcome-details-div-p-expertise p{
  color: var(--blue);
  padding: 0 6px;
 }
 .home-welcome-details-div-p-flexibility p{
  color: var(--white);
  padding: 0 6px;
 }
 .home-welcome-details-div-p-detail p{
  color: var(--white);
  padding: 0 6px;
 }


 .home-welcome-details-h3{
  color: var(--white);
  font-size: 24px
 }


.home-welcome-details-a{
  color: var(--white);
  font-size: 18px;
  background-image: linear-gradient(90deg,var(--blue), var(--blue-highlight));
  background-size: 300% auto;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px var(--black);
  transition: var(--transition);
  text-decoration: none;
  margin: 30px 0;
} 


.home-welcome-details-a:hover{
  background-position: right center;
  box-shadow: 1px 1px 6px var(--black);
  cursor: pointer;
  font-size: 18.5px;
  color: white;
}




/* ------------------------- FULLSCREEN CSS -------------------------- */

@media all and (min-width: 400px) and (max-width: 480px){

  .hero-container h2{
    font-size: 4vw; 
  }

  .home-welcome-details-container-bg{
    /* clip-path: polygon(5% 0, 95% 0, 95% 4%, 100% 4%, 100% 100%, 0 100%, 0 4%, 5% 4%); */
    clip-path: polygon(5% 2%, 95% 2%, 95% 0, 100% 0, 100% 100%, 0 100%, 0 0, 5% 0);
    padding-top: 100px;
  }

  .home-welcome-details-intro{
    font-size: 24px;
    margin: 0 auto;
  }

  .home-welcome-statement-container h3{
    font-size: 24px;
  }

  .home-welcome-details-div-p-expertise p{
    padding: 0 20px;
   }
   .home-welcome-details-div-p-flexibility p{
    padding: 0 20px;
   }
   .home-welcome-details-div-p-detail p{
    padding: 0 20px;
   }

}


@media all and (min-width: 480px) and (max-width: 499px){
  
  .home-bio{
    font-size: 4vw;
  }

  .hero-container h2{
    font-size: 4vw; 
  }

  .hero-container a{
    font-size: 4vw;
  }

  .hero-container a:hover{
    font-size: 4.2vw;
  }

  .home-welcome-statement-container a{
    font-size: 4vw;
  }

  .home-welcome-statement-container a:hover{
    font-size: 4.2vw;
  }

  .home-welcome-details-container-bg{
    clip-path: polygon(5% 2%, 95% 2%, 95% 0, 100% 0, 100% 100%, 0 100%, 0 0, 5% 0);
    padding-top: 100px;
  }

  .home-welcome-details-intro{
    font-size: 24px;
    margin: 0 auto;
  }

  .home-welcome-statement-container h3{
    font-size: 5vw;
  }

  .home-welcome-details-div-p-expertise p{
    padding: 0 20px;
   }
   .home-welcome-details-div-p-flexibility p{
    padding: 0 20px;
   }
   .home-welcome-details-div-p-detail p{
    padding: 0 20px;
   }

}


@media all and (min-width: 500px) and (max-width: 649px){
 
  .home-bio{
    font-size: 20px;
  }

  .hero-container h2{
    font-size: 4vw; 
  }

  .hero-container a{
    font-size: 24px;
  }

  .hero-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-statement-container a{
    font-size: 24px;
  }

  .home-welcome-statement-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-details-container-bg{
    clip-path: polygon(5% 2%, 95% 2%, 95% 0, 100% 0, 100% 100%, 0 100%, 0 0, 5% 0);
    padding-top: 100px;
  }

  .home-welcome-details-intro{
    font-size: 5vw;
    margin: 0 auto;
  }

  .home-welcome-statement-container h3{
    font-size: 5vw;
  }


 .home-welcome-details-img-expertise{
  height: 375px;
  width: 375px;
 
 }
 .home-welcome-details-img-flexibility{
  height: 375px;
  width: 375px;
 }
 .home-welcome-details-img-detail{
  height: 375px;
  width: 375px;
 }
  
 .home-welcome-details-div-p-expertise{
  height: 375px;
  width: 375px;
 }
 .home-welcome-details-div-p-flexibility{
  height: 375px;
  width: 375px;
 }
 .home-welcome-details-div-p-detail{
  height: 375px;
  width: 375px;
 }
  
  .home-welcome-details-div-p-expertise p{
    padding: 0 40px;
   }
   .home-welcome-details-div-p-flexibility p{
    padding: 0 40px;
   }
   .home-welcome-details-div-p-detail p{
    padding: 0 40px;
   }

   .home-welcome-details-a:hover{
    padding: 9.5px 20px 10px 20px;
   }

}


@media all and (min-width: 650px) and (max-width: 819px){

  .hero-container h2{
    font-size: 3vw; ;
  }

  .home-welcome-statement-container h3{
    font-size: 36px;
  }

  .home-welcome-details-intro{
    font-size: 36px;
    width: 260px;
  }

  .home-welcome-details-container-bg{
    clip-path: polygon(5% 2%, 95% 2%, 95% 0, 100% 0, 100% 100%, 0 100%, 0 0, 5% 0);
    padding-top: 80px;
  }

  .home-bio{
    font-size: 20px;
  }
  
  .hero-container a{
    font-size: 24px;
  }

  .hero-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-statement-container a{
    font-size: 24px;
  }

  .home-welcome-statement-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .home-welcome-details-alt{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }


 .home-welcome-details-img-expertise{
  height: 40vw;
  width: 40vw;
  margin: 0;
 
 }
 .home-welcome-details-img-flexibility{
  height: 40vw;
  width: 40vw;
  margin: 0;
 }
 .home-welcome-details-img-detail{
  height: 40vw;
  width: 40vw;
  margin: 0;
 }
  
 .home-welcome-details-div-p-expertise{
  height: 40vw;
  width: 40vw;
  margin: 0;
 }
 .home-welcome-details-div-p-flexibility{
  height: 40vw;
  width: 40vw;
  margin: 0;
 }
 .home-welcome-details-div-p-detail{
  height: 40vw;
  width: 40vw;
  margin: 0;
 }

 .home-welcome-details-div-p-expertise p{
  padding: 0 40px;
 }
 .home-welcome-details-div-p-flexibility p{
  padding: 0 40px;
 }
 .home-welcome-details-div-p-detail p{
  padding: 0 40px;
 }

 .home-welcome-details-a:hover{
  padding: 9.5px 20px 10px 20px;
 }

}


@media all and (min-width: 820px) and (max-width: 999px) {

  .hero-container h1{
    font-size: 66px; 
    max-width: 900px;
    margin: 40px auto 0 auto;
  }

  .hero-container h1 span{
    font-size: 48px; 
  }

  .hero-container h2{
    font-size: 24px; 
    max-width: 800px;
    margin: 30px auto 60px auto;
  }

  .hero-container a{
    font-size: 24px; 
  }

  .hero-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-statement-container a{
    font-size: 24px;
  }

  .home-welcome-statement-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-statement-container h3{
    font-size: 36px;
  }

  .home-welcome-details-intro{
    font-size: 36px;
    width: 260px;
  }

  .home-welcome-details-container-bg{
    margin: 0;
    clip-path: polygon(5% 3%, 95% 3%, 95% 0, 100% 0, 100% 100%, 0 100%, 0 0, 5% 0);
    padding-top: 80px;
  }

  .home-bio{
    font-size: 20px;
  }

 
  .home-welcome-details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .home-welcome-details-alt{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }


 .home-welcome-details-img-expertise{
  height: 300px;
  width: 300px;
  margin: 0;
 
 }
 .home-welcome-details-img-flexibility{
  height: 300px;
  width: 300px;
  margin: 0;
 }
 .home-welcome-details-img-detail{
  height: 300px;
  width: 300px;
  margin: 0;
 }
  
 .home-welcome-details-div-p-expertise{
  height: 300px;
  width: 300px;
  margin: 0;
 }
 .home-welcome-details-div-p-flexibility{
  height: 300px;
  width: 300px;
  margin: 0;
 }
 .home-welcome-details-div-p-detail{
  height: 300px;
  width: 300px;
  margin: 0;
 }

 .home-welcome-details-div-p-expertise p{
  padding: 0 40px;
 }
 .home-welcome-details-div-p-flexibility p{
  padding: 0 40px;
 }
 .home-welcome-details-div-p-detail p{
  padding: 0 40px;
 }

 .home-welcome-details-a:hover{
  padding: 9.5px 20px 10px 20px;
 }


}





@media all and (min-width: 1000px) {

  .hero-container h1{
    font-size: 66px; 
    max-width: 900px;
    margin: 60px auto 0 auto;
  }

  .hero-container h1 span{
    font-size: 48px; 
  }

  .hero-container h2{
    font-size: 24px; 
    max-width: 800px;
    margin: 30px auto 60px auto;
  }

  .hero-container a{
    font-size: 24px; 
  }

  .hero-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-statement-container a{
    font-size: 24px;
  }

  .home-welcome-statement-container a:hover{
    font-size: 24.5px;
  }

  .home-welcome-statement-container h3{
    font-size: 36px;
  }

  .home-welcome-details-intro{
    font-size: 36px;
    width: 260px;
  }

  .home-welcome-details-container-bg{
    margin: 0;
    clip-path: polygon(5% 3%, 95% 3%, 95% 0, 100% 0, 100% 100%, 0 100%, 0 0, 5% 0);
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-bio{
    font-size: 20px;
  }

 
  .home-welcome-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .home-welcome-details-alt{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;
  }


 .home-welcome-details-img-expertise{
  height: 300px;
  width: 300px;
  margin: 0;
 
 }
 .home-welcome-details-img-flexibility{
  height: 300px;
  width: 300px;
  margin: 0;
 }
 .home-welcome-details-img-detail{
  height: 300px;
  width: 300px;
  margin: 0;
 }
  
 .home-welcome-details-div-p-expertise{
  height: 300px;
  width: 300px;
  margin: 0;
 }
 .home-welcome-details-div-p-flexibility{
  height: 300px;
  width: 300px;
  margin: 0;
 }
 .home-welcome-details-div-p-detail{
  height: 300px;
  width: 300px;
  margin: 0;
 }

 .home-welcome-details-div-p-expertise p{
  padding: 0 40px;
 }
 .home-welcome-details-div-p-flexibility p{
  padding: 0 40px;
 }
 .home-welcome-details-div-p-detail p{
  padding: 0 40px;
 }

 .home-welcome-details-a:hover{
  padding: 9.5px 20px 10px 20px;
 }


}