
/* CSS set up for mobile first and Queries the larger screens */

.stone-gallery-container{
  background-color: var(--white);
}

.stone-gallery-container-h3{
  text-align: center;
  margin: 0;
  padding: 20px 0 20px 0;
  color: var(--black);
  font-family: 'Lato', sans-serif;
  font-size: 24px; 
}

/* the padding of 1 is to align the background color with the container or there is a gap under images */
.stone-gallery-item-container{
  padding: 0 0 60px 0;
}

.stone-gallery-item{
  width: 90vw;
  height: 240px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 1px 1px 4px var(--black);
}

.stone-gallery-img{
  flex-shrink: 1;
  min-width: 100%;
  min-height: 100%;
}


/* ------- SLIDER 0 - 700 --------- */
.stone-slider-img{
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translate(-50%, -0);
  width: 600px;
}

.stone-slider-container{
  background-color: rgba(44, 33, 67, 0);
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  transition: var(--transition-slider);
  top: 0;
  left: 0;
 }
 
 .stone-slider-container.activeStoneSlider{
   background-color: rgba(44, 33, 67, 0.7);
   opacity: 0;
   z-index: 1;
 }

 .stone-slider-X-div{
  position: relative;
  left: 147%;
  transform: translate(-50%, -0);
  top: 140px;
  cursor: pointer;
 }

 .stone-slider-X-div img{
  width: 30px;
 }

 .stone-slider-left{
  position: relative;
  top: 328px;
  left: 6.1%;
  transform: translate(-50%, -0);
  width: 100px;
  cursor: pointer;
 }

 .stone-slider-left img{
  width: 40px;
 }

 .stone-slider-right{
  position: relative;
  top: 283px;
  left: 103%;
  transform: translate(-50%, -0);
  width: 100px;
  cursor: pointer;
 }

 .stone-slider-right img{
  width: 40px;
 }

 .stone-slider-change{
  width: 650px;
  margin: 0 auto;
 }


@media all and (min-width: 600px) and (max-width: 699px) {
  
  .stone-gallery-container-h3{
    font-size: 30px; 
  }
  
}

@media all and (min-width: 700px) and (max-width: 929px) {

  .stone-gallery-item{
    width: 300px;
    height: 200px;
    margin: 10px auto;
    cursor: pointer;
  }

  .stone-gallery-item-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 680px;
    margin: 0 auto;

  }

  .stone-gallery-container-h3{
    font-size: 30px; 
  }

  /* ------- SLIDER 700 - 930 --------- */
 .stone-slider-container.activeStoneSlider{
   opacity: 1;
 }


}

@media all and (min-width: 930px) and (max-width: 1099px) {

  .stone-gallery-item{
    width: 300px;
    height: 200px;
    margin: 10px auto;
    cursor: pointer;
  }

  .stone-gallery-item-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 680px;
    margin: 0 auto;

  }

  .stone-gallery-container-h3{
    font-size: 30px; 
  }


  /* ------- SLIDER 930 - 1099 --------- */
  .stone-slider-container.activeStoneSlider{
  opacity: 1;
  }


}


@media all and (min-width: 1100px){

  .stone-gallery-item{
    width: 300px;
    height: 200px;
    margin: 10px 20px;
    cursor: pointer;
  }
  
  .stone-gallery-item-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 1000px;
    margin: 0 auto;
    padding-right: 20px;
  
  }
  
  .stone-gallery-container-h3{
    font-size: 36px; 
    padding: 0 0 20px 0;
  }

/* ------- SLIDER 1100+ --------- */
.stone-slider-container.activeStoneSlider{
  opacity: 1;
  }
  
}